const devConfig = {
    AUTH_DOMAIN: 'http://localhost:8080/vitag-auth/rest/authc/ajax',
    OVERRIDE_DOMAIN: 'http://localhost:8080/vitag-auth/rest/authc/override',
    CORE_DOMAIN: 'http://localhost:8080/vitag-core/rest',
    IS_BETA: true,
    TITLE_BASE: 'ViTAG — ',
    GOOGLE_API_KEY: 'AIzaSyAfs_PRenI8zyxD9K4lRN4QngaG4aLXo5E',
    STRIPE_API_KEY: 'pk_test_51Q2kQvEgYckxSUaub4pUVlaNlHqjq8z2pN3AsFu6JBmMPgPJFnyyq32A15vSeOeWgJQ5QD4toYua6YT2oalRK8od00Qj8dQwNR',
};

const prodConfig = {
    AUTH_DOMAIN: 'https://backend.vitag.app/vitag-auth/rest/authc/ajax',
    OVERRIDE_DOMAIN: 'https://backend.vitag.app/vitag-auth/rest/authc/override',
    CORE_DOMAIN: 'https://backend.vitag.app/vitag-core/rest',
    IS_BETA: false,
    TITLE_BASE: 'ViTAG — ',
    GOOGLE_API_KEY: 'AIzaSyAfs_PRenI8zyxD9K4lRN4QngaG4aLXo5E',
    STRIPE_API_KEY: '',
};

const betaConfig = {
    AUTH_DOMAIN: 'https://stagging.vitag.app/vitag-auth/rest/authc/ajax',
    OVERRIDE_DOMAIN: 'https://stagging.vitag.app/vitag-auth/rest/authc/override',
    CORE_DOMAIN: 'https://stagging.vitag.app/vitag-core/rest',
    IS_BETA: true,
    TITLE_BASE: 'ViTAG — ',
    GOOGLE_API_KEY: 'AIzaSyAfs_PRenI8zyxD9K4lRN4QngaG4aLXo5E',
    STRIPE_API_KEY: 'pk_test_51Q2kQvEgYckxSUaub4pUVlaNlHqjq8z2pN3AsFu6JBmMPgPJFnyyq32A15vSeOeWgJQ5QD4toYua6YT2oalRK8od00Qj8dQwNR',
};

let configToUse;
switch(process.env.REACT_APP_STAGE) {
    case 'prod': configToUse = prodConfig; break;
    case 'beta': configToUse = betaConfig; break;
    default: configToUse = devConfig;
}

const config = configToUse;
export { config };

