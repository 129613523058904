import React, { useEffect, useRef, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useParams, useHistory, useLocation } from 'react-router';
import { Typologies } from '../utils/TypologiesEnum';
import VideoJS from '../general/VideoJS';
import { convertSecondsToTime, convertMillisecondsToTime } from '../utils/NabUtils';
import { getVideoDetail, saveLastPlaying } from '../services/VitagVideosService';
import { addMarkerAtVideo, deleteMarkerAtVideo } from '../services/VitagMarkersService';
import { listAllTags } from '../services/VitagTagsService';
import { listFavoriteTagsByVideo } from '../services/VitagFavoritesService';
import { MarkersBar } from './MarkersBar';
import { FavoritesBar } from './FavoritesBar';
import { ModalManageMarker } from '../modals/ModalManageMarker';
import { ModalButtonPad } from '../modals/ModalButtonPad';
import { ModalShare } from '../modals/ModalShare';
import { ModalViewMarker } from '../modals/ModalViewMarker';
import { ModalManageFavs } from '../modals/ModalManageFavs';
import { ConfirmModal } from '../general/ConfirmModal';
import { getPreference, getPreferenceAsBool, getPreferenceAsNumber, PREFERENCES, savePreference } from '../utils/preferences/user-preferences';
import { VimeoPlayer } from './providers/VimeoPlayer';
import { uploadImageToS3, listTypologiesByInternalId } from '../services/CoreService';
import { Trans, useTranslation } from 'react-i18next';
import { MarkerSummary } from '../general/MarkerSummary';
import { ProfileIndicator } from '../general/ProfileIndicator';
import { TwitchPlayer } from './providers/TwitchPlayer';
import { config } from '../config';
import { AVAILABLE_ACTIONS, eventAtVideo } from '../services/VitagEventsService';
import { ModalShowEvents } from '../modals/ModalShowEvents';

function VideoPlayer() {

    let { hashId } = useParams();
    const playerRef = React.useRef(null);
    const firstPlay = React.useRef(true);
    const history = useHistory();
    const [ytPlayer, setYtPlayer] = useState();
    const [vimeoPlayer, setVimeoPlayer] = useState();
    const [twitchPlayer, setTwitchPlayer] = useState();
    const [vtgVideo, setVtgVideo] = useState();
    const [errorCode, setErrorCode] = useState('');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [videoDuration, setVideoDuration] = useState(0.1);
    const [needRefresh, setNeedRefresh] = useState();
    const [repaintMarkers, setRepaintMarkers] = useState();
    const [availableVtgTags, setAvailableVtgTags] = useState([]);
    const [favoriteVtgTags, setFavoriteVtgTags] = useState([]);
    const [allProjections, setProjections] = useState([]);
    const [quickTag, setQuickTag] = useState(
        getPreferenceAsBool(PREFERENCES.USE_TURBO)
    );
    const [showNewTagModal, setShowNewTagModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [markers, setMarkers] = useState([]);
    const [showConfirmDeleteMarkerModal, setShowConfirmDeleteMarkerModal] = useState(false);
    const [clipTime, setClipTime] = useState(5);
    const [currentTag, setCurrentTag] = useState();
    const [currentMarker, setCurrentMarker] = useState();
    const [currentThumbnail, setCurrentThumbnail] = useState('');
    const [currentTime, setCurrentTime] = useState(-1);
    const [currentTimeMs, setCurrentTimeMs] = useState(-1);
    const [currentDetailTime, setCurrentDetailTime] = useState('');
    const [markerSummary, setMarkerSummary] = useState();
    const [showSideBar, setShowSideBar] = useState(false);
    const [showLocalVideoError, setShowLocalVideoError] = useState(false);
    const [showSelectLocalVideoModal, setShowSelectLocalVideoModal] = useState(false);
    const [showModalManageMarker, setShowModalManageMarker] = useState(false);
    const [showModalViewMarker, setShowModalViewMarker] = useState(false);
    const [showModalManageFavs, setShowModalManageFavs] = useState(false);
    const [showModalMoreInfo, setShowModalMoreInfo] = useState(false);
    const [playingVideo, setPlayingVideo] = useState(false);
    const [playingVitagSummary, setPlayingVitagSummary] = useState(false);
    const [showMarkerVitagSummary, setShowMarkerVitagSummary] = useState(true);
    const [showSummaryContainer, setShowSummaryContainer] = useState(false);
    const [showTimerOnly, setShowTimerOnly] = useState(false);
    const [disableKeys, setDisableKeys] = useState(false);
    const [lockMarkerVitagSummary, setLockMarkerVitagSummary] = useState(() => {
        const preference = getPreference(PREFERENCES.LOCK_MARKER_SUMMARY);
        if (preference === 'true') {
            return 1296;
        } else if (preference === 'false') {
            return 1297;
        } else {
            return getPreferenceAsNumber(PREFERENCES.LOCK_MARKER_SUMMARY);
        }
    });
    const [firstRefresh, setFirstRefresh] = useState(false);
    const [externSeekTo, setExternSeekTo] = useState(0);
    const [projectionType, setProjectionType] = useState(0);
    const [cancelableTimers, setCancelableTimers] = useState([]);
    const [recentMarkers, setRecentMarkers] = useState([]);
    const [videoJsOptions, setVideoJsOptions] = useState({
        autoplay:false, controls:true, responsive:true, liveui:true, muted:true, fluid:false, preset:'auto'
    });
    const { t } = useTranslation();
    const location = useLocation();
    const [lastPlaying, setLastPlaying] = useState(0);
    const [lastPlayingDate, setLastPlayingDate] = useState();
    const keyboard = 'qwertyuiop';

    const markersRef = useRef(markers);
    const ytRef = useRef(ytPlayer);
    const vimeoRef = useRef(vimeoPlayer);
    const twitchRef = useRef(twitchPlayer);
    const playPauseRef = useRef(playingVideo);
    const lockRef = useRef(lockMarkerVitagSummary);

    const getMimeType = (url) => {
        const extension = url.split('.').pop().split('?')[0];
        const mimeTypes = {
          mp4: 'video/mp4',
          webm: 'video/webm',
          ogg: 'video/ogg',
        };
        return mimeTypes[extension] || 'video/mp4';
      };
    
    useEffect(() => {
        const interval = setInterval(
            () => setLastPlayingDate(new Date()),
            10000
        );
        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        const interval = setInterval(
            () => {
                
                if (lockRef?.current === Typologies.LMSO_TIMER && markersRef.current && playPauseRef.current) {
                    let ct = 0;
                    if (ytRef.current) {
                        ct = ytRef.current.getCurrentTime();
                    }
                    if (vimeoRef.current) {
                        ct = vimeoRef.current.getCurrentTime();
                    }
                    if (twitchRef.current) {
                        ct = twitchRef.current.getCurrentTime();
                    }
                    const vfPlayer = getVideoFileObject();
                    if (vfPlayer) {            
                        ct = vfPlayer.currentTime;
                    }
                        const milliseconds = ct * 1000;
                        
                        const cMarker = markersRef.current.find(mark => {
                            const startTime = (mark.startMilliSecond > 0 ? mark.startMilliSecond : mark.startSecond * 1000) - mark.startOffset;
                            const endTime = (mark.startMilliSecond > 0 ? mark.startMilliSecond : mark.startSecond * 1000) + mark.endOffset;
                            return milliseconds >= startTime && milliseconds <= endTime;
                        });

                        if (cMarker) {
                            setMarkerSummary(cMarker);
                        } else {
                            setMarkerSummary(undefined);
                            closeMarkerSummary();
                        }
                }
            },
            250
        );
        return () => clearInterval(interval);    
    }, [])

    useEffect(() => {
        tryToSaveLastPlaying();
    }, [lastPlayingDate]);

    useEffect(() => {
        markers.sort((a, b) => {
            const aTime = a.startMilliSecond > 0 ? a.startMilliSecond : a.startSecond * 1000;
            const bTime = b.startMilliSecond > 0 ? b.startMilliSecond : b.startSecond * 1000;
            return aTime - bTime;
        });
        recentMarkers.sort((a, b) => {
            const aTime = a.startMilliSecond > 0 ? a.startMilliSecond : a.startSecond * 1000;
            const bTime = b.startMilliSecond > 0 ? b.startMilliSecond : b.startSecond * 1000;
            return aTime - bTime;
        });
        markersRef.current = markers;
    }, [markers, recentMarkers]);

    useEffect(() => {
        ytRef.current = ytPlayer;
        vimeoRef.current = vimeoPlayer;
        twitchRef.current = twitchPlayer;
    }, [ytPlayer, vimeoPlayer, twitchPlayer]);

    useEffect(() => {
        listTypologiesByInternalId(Typologies.PROJECTION_TYPE)
            .then(resp => {
                setProjections(resp.data);
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Error getting projections', err)
            });
        Promise.all([getVideoDetail(hashId), listAllTags('', 0, 50, false, true)])
            .then(allResp => {
                const leVideo = allResp[0].data;
                setProjectionType(leVideo.projectionType);
                document.title = config.TITLE_BASE + leVideo.title;
                setVtgVideo(leVideo);
                if(config.IS_BETA) console.log(leVideo);
                saveEventAtVideo(AVAILABLE_ACTIONS.VIDEO.OPEN, leVideo.videoId);
                const timeMs = leVideo.timeInMilliSeconds/1000;
                if (leVideo.timeInMilliSeconds > 0) {
                    setVideoDuration(timeMs);
                } else {
                    setVideoDuration(leVideo.timeInSeconds);    
                }                
                if (leVideo.videoProvider.internalId === Typologies.VT_LOCAL_VIDEO) {
                    if(config.IS_BETA) console.log('local-url >>>> ' + location.state?.localUrl);
                    if (location.state?.localUrl) {
                        setVideoJsOptions({...videoJsOptions, sources:[{
                            src:location.state?.localUrl,
                            type:location.state?.fileType
                        }]});
                    }
                    if (leVideo.fileType != null && leVideo.url != null) {
                        setVideoJsOptions({...videoJsOptions, sources:[{
                            src:leVideo.url,
                            type:leVideo.fileType
                        }]});
                    }
                    // setShowSelectLocalVideoModal(true);
                    // setShowLocalVideoError(true);
                } else if (leVideo.videoProvider.internalId === Typologies.VT_EXTERN_VIDEO) {
                    const type = getMimeType(leVideo.url);
                    setVideoJsOptions({...videoJsOptions, sources:[{src:leVideo.url, type: type}]});
                } else {
                    setVideoJsOptions({...videoJsOptions, sources:[{src:leVideo.url}]});
                }
                const allTags = allResp[1].data;
                setAvailableVtgTags(allTags);
                if (leVideo.videoProvider.internalId === Typologies.VT_YOUTUBE) {
                    setTimeout(() => {
                        new window.YT.Player(
                            "player", {
                                videoId: vtgVideo?.onlineId,
                                events: {
                                    onReady: onPlayerReady,
                                    onStateChange: onYtStateChanged
                                }
                            }
                        );
                    }, 2000);
                }
                return listFavoriteTagsByVideo(leVideo.videoId);
            }).then(favs => {
                const transformedData = favs.data.map(item => {
                    return { position: item.position, ...item.tag };
                });
                transformedData.sort((a, b) => a.position - b.position);
                setFavoriteVtgTags(transformedData);
            }).catch(err => {
                if(config.IS_BETA) console.error("Error getting initial data", err);
            });
    }, [location, hashId]);

    const handleKeyPress = (event) => {
        const key = event.key.toLowerCase();
        const index = keyboard.indexOf(key);
        if (index !== -1) {
            if(config.IS_BETA) console.log(!disableKeys, !showModalMoreInfo, !showShareModal, !showModalManageMarker, !showModalManageFavs, lockMarkerVitagSummary, !showSummaryContainer);
            if (!disableKeys && !showModalMoreInfo && !showShareModal && !showModalManageMarker  && !showModalManageFavs && (lockMarkerVitagSummary == Typologies.LMSO_INACTIVE || !showSummaryContainer)) {
                const object = favoriteVtgTags[index];
                if (object) {
                    if(config.IS_BETA) console.log('Marker found:', object);
                    handleQuickMarkerClick(null, object);
                } 
            }
            
        }
    };
    
      useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
          window.removeEventListener('keydown', handleKeyPress);
        };
    }, [favoriteVtgTags, handleKeyPress]);

    useEffect(() => {
        if(config.IS_BETA) console.log('CurrentTime: ' + currentTimeMs)
    }, [currentTimeMs]);

    const saveEventAtVideo = (action, videoId) => {
        if(! videoId) {
            videoId = vtgVideo.videoId;
        }
        eventAtVideo(videoId, action)
            .then(_ => {
                if(config.IS_BETA) console.log(`Event '${action}' saved`)
            })
            .catch(_ => {
                if(config.IS_BETA) console.error(`Error saving '${action}'`)
            });
    }

    const tryToSaveLastPlaying = (
        successCallback = (ct) => {
            if(config.IS_BETA) console.log('Last playing saved: ' + ct)
        }
    ) => {
        getCurrentTime()
            .then(ct => {
                if (ct == lastPlaying) {
                    if(config.IS_BETA) console.log('Skip saving last playing');
                    successCallback(ct);
                    return;
                }
                setLastPlaying(ct);
                if (ct > 0) {
                    saveLastPlaying(hashId, ct)
                        .then(_ => successCallback(ct))
                        .catch(err => {
                            if(config.IS_BETA) console.log('Last playing error', err)
                        });
                } else {
                    successCallback(ct);
                }
            });
    }

    const saveLastPlayingAndExit = () => {
        tryToSaveLastPlaying(_ => history.replace('/videos'));
    }

    const reloadMarkers = () => {
        setNeedRefresh(new Date());
        setFirstRefresh(false);
    }

    const closeMarkerSummary = () => {
        setShowSummaryContainer(false);
        if (playingVitagSummary) {
            setShowMarkerVitagSummary(false);
        }
    }

    const closeMarkerManager = () => {
        setShowModalManageMarker(false);
        setShowSummaryContainer(false);
        const delay = setTimeout(() => {
            setShowTimerOnly(false);
        }, (50));

        clearTimeout(delay);
    }

    const handleFileSelection = (event) => {
        const localFile = event.target.files[0];
        const url = URL.createObjectURL(localFile);
        setVtgVideo({...vtgVideo, url});
        setVideoJsOptions(
            {
                ...videoJsOptions,
                sources:[
                    {src:url, type:'video/mp4'},
                    {src:url, type:'video/ogg'},
                    {src:url, type:'video/webm'}
                ]
            });
        setShowLocalVideoError(false);
        setShowSelectLocalVideoModal(false);
        setTimeout(() => {
            const duration = getVideoFileObject().duration;
            setVideoDuration(duration);
        }, 1200);
    }

    const newMarkerListener = (newMarker) => {
        if(config.IS_BETA) console.log('New marker on videoPlayer', newMarker);
        setRecentMarkers([...recentMarkers, newMarker.videoMarkerId]);
        setCurrentMarker(newMarker);
        setMarkerSummary(newMarker);
        setCurrentMarkerId(newMarker.videoMarkerId);
        scrollToMarker(newMarker.videoMarkerId);
        reloadMarkers();
    }

    const updateFavoriteTags = () => {
        listFavoriteTagsByVideo(vtgVideo?.videoId)
            .then(favs => {
                const transformedData = favs.data.map(item => {
                    return { position: item.position, ...item.tag };
                });
                transformedData.sort((a, b) => a.position - b.position);

                setFavoriteVtgTags(transformedData);
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Error getting fav tags', err)
            });
    }

    const getVideoFileObject = () => document.getElementById('videoFilePlayer_html5_api');

    function onPlayerReady(event) {
        setVideoDuration(event.target.getDuration())
        setYtPlayer(event.target);
        event.target.seekTo(location.state?.last || 0);
        event.target.pauseVideo();
        setRepaintMarkers(true);
    }

    function onYtStateChanged(stt) {
        const newDuration = ytPlayer?.getDuration();
        if (newDuration && newDuration != videoDuration) {
            setVideoDuration(newDuration);
        }
        switch(stt.data) {
            case window.YT.PlayerState.ENDED:
                setPlayingVideo(false);
                playPauseRef.current = false;
                break;
            case window.YT.PlayerState.PLAYING:
                setPlayingVideo(true);
                playPauseRef.current = true;
                break;
            case window.YT.PlayerState.PAUSED:
                setPlayingVideo(false);
                playPauseRef.current = false;
                break;
        }
    }

    const sidebarMoveTo = (clickedMarker) => {
        setCurrentMarker(clickedMarker);
        setMarkerSummary(clickedMarker);
        const element = document.getElementById('marker-on-sidebar-'+clickedMarker?.videoMarkerId);

        scrollToMarker(clickedMarker.videoMarkerId);

        const seconds = clickedMarker.startMilliSecond > 0 ? (clickedMarker.startMilliSecond / 1000) : clickedMarker.startSecond;
        moveTo(seconds);
    }

    const moveTo = (seconds) => {
        if(config.IS_BETA) console.log('Moving to:', seconds);
        if(config.IS_BETA) console.log('Duration:', videoDuration);
        if (seconds < 0) {
            seconds = 0;
        }
        if (seconds > videoDuration) {
            seconds = videoDuration;
        }
        setExternSeekTo(seconds);
        extractCurrentTime(seconds);
        if (ytPlayer) {
            ytPlayer.seekTo(seconds);
            return;
        }
        const vfPlayer = getVideoFileObject();
        if (vfPlayer) {
            vfPlayer.currentTime = seconds;
            return;
        }
    }

    const goForward = (offset = 3) => {
        getCurrentTime()
            .then(ct => {
                const milliseconds = ct * 1000;
                const timeMs = convertMillisecondsToTime(milliseconds);
                const time = convertSecondsToTime(ct);
                setCurrentTime(ct);
                setCurrentTimeMs(milliseconds);
                setCurrentDetailTime(timeMs);
                
                moveTo(ct + offset);
            });
    }

    const goBackward = (offset = 2) => {
        getCurrentTime()
            .then(ct => {
                const milliseconds = ct * 1000;
                const timeMs = convertMillisecondsToTime(milliseconds);
                if(config.IS_BETA) console.log(timeMs);
                const time = convertSecondsToTime(ct);
                setCurrentTime(ct);
                setCurrentTimeMs(milliseconds);
                setCurrentDetailTime(timeMs);

                moveTo(ct - offset);
            });
        
    }

    /*const goTimerForward = () => {
        getCurrentTime()
            .then(ct => {
                if(config.IS_BETA) console.log(currentMarker.endOffset);
                if(config.IS_BETA) console.log(currentTime);
                moveTo(currentTime + (currentMarker.endOffset/1000));
            });
    }

    const goTimerBackward = () => {
        getCurrentTime()
            .then(ct => {
                moveTo(currentTime - (currentMarker.startOffset/1000));
            });
    }*/

    const playVideo = () => {
        if (ytPlayer) {
            ytPlayer.playVideo();
            return;
        }
        const vfPlayer = getVideoFileObject();
        if (vfPlayer) {
            vfPlayer.play();
            return;
        }
        setPlayingVideo(true);
        playPauseRef.current = true;
    }

    const pauseVideo = () => {
        if (ytPlayer) {
            ytPlayer.pauseVideo();
            return;
        }
        const vfPlayer = getVideoFileObject();
        if (vfPlayer) {
            vfPlayer.pause();
            return;
        }
        getCurrentTime()
            .then(seconds => {
                if(config.IS_BETA) console.log(`pause New marker at ${seconds}`);
                extractCurrentTime(seconds);
                setPlayingVideo(false);
                playPauseRef.current = false;
            });
    }

    const getCurrentTime = () => {
        if (ytPlayer) {
            return new Promise(r => r(ytPlayer.getCurrentTime()));
        }
        if (vimeoPlayer) {
            return vimeoPlayer.getCurrentTime();
        }
        if (twitchPlayer) {
            if(config.IS_BETA) console.log('Twitch currentTime:', twitchPlayer.getCurrentTime());
            return new Promise(r => r(twitchPlayer.getCurrentTime()));
        }
        const vfPlayer = getVideoFileObject();
        if (vfPlayer) {
            if(config.IS_BETA) console.log('vfPlayer, ' + vfPlayer.currentTime);
            return new Promise(r => r(vfPlayer.currentTime));
        }
        return new Promise(r => r(currentTime));
    }

    const handleQuickMarkerClick = (event, tag) => {
        if (quickTag || event?.shiftKey) {
            addQuickTagAtVideo(tag);
            return;
        }
        setCurrentMarker(null);
        setCurrentTag(tag);
        pauseVideo();
        openNewMarkerModal();
    }

    const handleClickExtendMarker = () => {
        setCurrentTag(null);
        pauseVideo()
        openNewMarkerModal(3);
    }

    const openNewMarkerModal = (delay = 0) => {
        getCurrentTime().then(seconds => {
            if(config.IS_BETA) console.log(`New marker at ${seconds}`);
            if (delay > 0 && playingVideo && seconds > delay) {
                moveTo(seconds - delay);
            }
            const milliseconds = seconds * 1000;
            const timeMs = convertMillisecondsToTime(milliseconds);
            const time = convertSecondsToTime(seconds);
            if(config.IS_BETA) console.log(`New marker at ${timeMs}`);
            setCurrentTime(seconds);
            setCurrentTimeMs(milliseconds);
            setCurrentDetailTime(timeMs);
            setCurrentThumbnail(getVideoThumbnail());
            setCurrentMarker(null);
            setMarkerSummary(undefined);
            setShowModalManageMarker(true);
            setShowTimerOnly(false);
        });
    }

    const extractCurrentTime = (seconds) => {
        if(config.IS_BETA) console.log('Extracting current time: ' + seconds);
        const milliseconds = seconds * 1000;
        const detailTime = convertSecondsToTime(seconds);
        const detailTimeMs = convertMillisecondsToTime(milliseconds);
        setCurrentTime(seconds);
        setCurrentTimeMs(milliseconds);
        setCurrentDetailTime(detailTimeMs);
    }

    const addQuickTagAtVideo = (leTag) => {
        getCurrentTime().then(ct => {
            if(config.IS_BETA) console.log(`quick New marker at ${ct}`);
            const durationMs = ct * 1000;
            const quickTag = {
                videoId: vtgVideo?.videoId,
                title: leTag.name,
                description: '',
                startSecond: ct,
                startMilliSecond: durationMs,
                startOffset: getPreferenceAsNumber(PREFERENCES.PLAYER_DEFAULT_OFFSET_BEFORE),
                endOffset: getPreferenceAsNumber(PREFERENCES.PLAYER_DEFAULT_OFFSET_AFTER),
                markerTags:[{vtgTag:leTag}]
            };
            const thumbnail = getVideoThumbnail();
            setShowSummaryContainer(true);
            if (thumbnail.startsWith('data:image')) {
                uploadImageToS3(thumbnail)
                    .then(resp => {
                        quickTag.thumbnail = resp.data.imageUrl;
                        addTagAtVideo(quickTag);
                    })
                    .catch();
                return;
            }
            quickTag.thumbnail = thumbnail + '?temp';
            addTagAtVideo(quickTag);
            
        });
    }

    const addTagAtVideo = (newTag) => {
        addMarkerAtVideo(newTag)
        .then(resp => {
            const tagAtVideo = resp.data;
            newMarkerListener(tagAtVideo);
        })
        .catch(err => {
            if(config.IS_BETA) console.error('Error adding tag', err)
        });
    }

    const shareVideo = () => setShowShareModal(true);

    const triggerSideBar = () => {
        if (!showSideBar) {
            reloadMarkers();
        }
        setShowSideBar(!showSideBar);
    };

    const getVideoThumbnail = () => {
        if (vtgVideo?.videoProvider.internalId === Typologies.VT_YOUTUBE) {
            return vtgVideo?.miniature;
        }
        if (vtgVideo?.videoProvider.internalId === Typologies.VT_VIMEO) {
            return vtgVideo?.miniature;
        }
        if (vtgVideo?.videoProvider.internalId === Typologies.VT_TWITCH) {
            return vtgVideo?.miniature;
        }
        const video = getVideoFileObject();
        const canvas = document.createElement('canvas');
        canvas.width = video.offsetWidth / 3;
        canvas.height = video.offsetHeight / 3;
        const canvasContext = canvas.getContext('2d');
        canvasContext.fillStyle = '#000000';
        canvasContext.fillRect(0, 0, canvas.width, canvas.height);
        canvasContext.drawImage(video,
            0, 0, video.videoWidth, video.videoHeight,
            0, 0, video.offsetWidth / 3, video.offsetHeight / 3
        );
        if(config.IS_BETA) console.log(`Source => ${video.videoWidth}, ${video.videoHeight}`)
        if(config.IS_BETA) console.log(`Destiny => ${video.offsetWidth / 3}, ${video.offsetHeight / 3}`)
        try {
            const img = canvas.toDataURL('image/png');
            return img;
        } catch (ex) {
            if(config.IS_BETA) console.error('Error getting thumbnail', ex.message);
        }
        return vtgVideo?.miniature;
    }

    const playCallback = () => {
        setPlayingVideo(true);
        playPauseRef.current = true;
        if (firstPlay.current) {
            setRepaintMarkers(new Date());
        }
        firstPlay.current = false;
    }

    const handlePlayerReady = (player) => {
        playerRef.current = player;
        player.on('error', err => {
            setShowErrorModal(true);
            setErrorCode(t('errorcode_videoaccess'));
        });
        player.on('ready', _ => {
            setTimeout(() => {
                const duration = getVideoFileObject().duration;
                setVideoDuration(duration);
            }, 3000);
        });
        player.on('play', playCallback);
        const videoObj = getVideoFileObject();
        videoObj.onpause = () => {
            if(config.IS_BETA) console.log('Local video pause');
            setPlayingVideo(false);
            playPauseRef.current = false;
        }
        videoObj.onplay = () => {
            if(config.IS_BETA) console.log('Local video play');
            setPlayingVideo(true);
            playPauseRef.current = true;
        }
    }

    const startMarkerEdit = (marker) => {
        const seconds = marker.startMilliSecond > 0 ? (marker.startMilliSecond / 1000) : marker.startSecond;
        moveTo(seconds);
        setMarkerSummary(undefined);
        setCurrentTag(null);
        setCurrentMarker(marker);
        setCurrentThumbnail(marker.thumbnail);
        if (marker.startMilliSecond > 0) {
            setCurrentTime(marker.startMilliSecond);
            setCurrentDetailTime(convertMillisecondsToTime(marker.startMilliSecond));
        } else {
            setCurrentTime(marker.startSecond);
            setCurrentDetailTime(convertSecondsToTime(marker.startSecond));
        }
        setShowModalManageMarker(true);
        setShowTimerOnly(false);
    }

    const startMarkerTimerEdit = (marker) => {
        const seconds = marker.startMilliSecond > 0 ? (marker.startMilliSecond / 1000) : marker.startSecond;
        moveTo(seconds);
        setMarkerSummary(undefined);
        setCurrentTag(null);
        setCurrentMarker(marker);
        setCurrentThumbnail(marker.thumbnail);
        if (marker.startMilliSecond > 0) {
            setCurrentTime(marker.startMilliSecond);
            setCurrentDetailTime(convertMillisecondsToTime(marker.startMilliSecond));
        } else {
            setCurrentTime(marker.startSecond);
            setCurrentDetailTime(convertSecondsToTime(marker.startSecond));
        }
        setShowModalManageMarker(true);
        setShowTimerOnly(true);
    }

    const showMarkerDetail = (marker) => {
        setMarkerSummary(undefined);
        setCurrentMarker(marker);
        setCurrentThumbnail(marker.thumbnail);
        if (marker.startMilliSecond > 0) {
            setCurrentDetailTime(convertMillisecondsToTime(marker.startMilliSecond));
        } else {
            setCurrentDetailTime(convertSecondsToTime(marker.startSecond));
        }
        setShowModalViewMarker(true);
    }

    const playVitagSummary = () => {
        setPlayingVitagSummary(true);
        setShowMarkerVitagSummary(true);
        setShowSummaryContainer(true);
        const lastCancelables = [...cancelableTimers];
        lastCancelables.forEach(lc => clearTimeout(lc));
        getCurrentTime()
            .then(ct => {
                if(ct <= 0) {
                    if(config.IS_BETA) console.log('Playing all markers, currentTime is <= 0');
                    startVitagSummary(markers);
                    return;
                }
                const markersAfterCurrentTime = markers.filter(mk => (mk.startMilliSecond > 0 ? (mk.startMilliSecond / 1000) : mk.startSecond) > ct);
                if(markersAfterCurrentTime.length == 0) {
                    if(config.IS_BETA) console.log('Playing all markers, no markers after currentTime');
                    startVitagSummary(markers);
                    return;
                }
                if(config.IS_BETA) console.log('Playing markers after at currentTime');
                startVitagSummary(markersAfterCurrentTime);
                return;
            });
    }

    const itemRefs = useRef({});
    const [currentMarkerId, setCurrentMarkerId] = useState(0);

    const scrollToMarker = (videoMarkerId) => {
        const timer = setTimeout(() => {
            if (itemRefs.current[videoMarkerId] && showSideBar) {
                itemRefs.current[videoMarkerId].scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }, 200);
        return () => clearTimeout(timer);
    }

    useEffect(() => {
        if (showSideBar) {
            scrollToMarker(currentMarkerId);
        }
      }, [showSideBar, currentMarkerId]);

    const startVitagSummary = (markersToResume) => {
        const cancelables = [];
        const lastMarkerIdx = markersToResume.length - 1;
        let accumulatedTime = 0;
        markersToResume.forEach((item, i) => {
            const idx = i;
            const seconds = item.startMilliSecond > 0 ? (item.startMilliSecond / 1000) : item.startSecond;
            const waitTime = (item.startOffset + item.endOffset);
            if (i == 0) {
                moveTo(seconds - item.startOffset/1000);
                playVideo();
                setCurrentMarker(item);
                setMarkerSummary(item);
                setCurrentMarkerId(item.videoMarkerId);
                scrollToMarker(item.videoMarkerId);
                accumulatedTime += waitTime;
                return;
            }
            if (i !== 0) {
                const id = setTimeout(() => {
                    moveTo(seconds - item.startOffset/1000);
                    playVideo();
                    setMarkerSummary(item);
                    setCurrentMarker(item);
                    setCurrentMarkerId(item.videoMarkerId);
                    scrollToMarker(item.videoMarkerId);
                    if(idx == lastMarkerIdx) {
                        setPlayingVitagSummary(false);
                    }
                }, (accumulatedTime));
                accumulatedTime += waitTime;
                cancelables.push(id);    
            }
            
        });
        setCancelableTimers(cancelables);
    }

    const stopVitagSummary = () => {
        const lastCancelables = [...cancelableTimers];
        lastCancelables.forEach(lc => clearTimeout(lc));
        setCancelableTimers([]);
        setMarkerSummary(undefined);
        setPlayingVitagSummary(false);
        setShowMarkerVitagSummary(true);
        setShowSummaryContainer(false);
        pauseVideo();
    }

    const setMarkerSummaryFromMarkerBar = (marker) => {
        setShowMarkerVitagSummary(Boolean(marker));
        setMarkerSummary(marker);
        setShowSummaryContainer(true);
    }

    const changeClipTime = (ct) => {
        savePreference(PREFERENCES.CLIP_TIME, ct);
        setClipTime(ct);
    }

    const confirmDeleteMarker = (markerTag) => {
        if(config.IS_BETA) console.log('Delete marker?', markerTag);
        setCurrentMarker(markerTag);
        setShowConfirmDeleteMarkerModal(true);
    }

    const deleteCurrentMarker = () => {
        deleteMarkerAtVideo(currentMarker.videoMarkerId)
            .then(resp => {
                if(config.IS_BETA) console.log('Marker deleted');
                reloadMarkers();
                setShowSummaryContainer(false);
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Error deleting marker...', err)
            });
    }

    const goBack = () => {
        history.goBack();
    }

    const refreshPage = () => {
        window.location.reload();
    }

    const changeQuickTag = (qt) => {
        savePreference(PREFERENCES.USE_TURBO, qt);
        setQuickTag(qt);
    }

    const toggleLockMarkerSummary = (newLock) => {
        savePreference(PREFERENCES.LOCK_MARKER_SUMMARY, newLock);
        setLockMarkerVitagSummary(newLock);
        lockRef.current = newLock;
    }

    const showVideoEvents = () => {
        setShowModalMoreInfo(true);
    }

    const [closeMenu, setCloseMenu] = useState(true);

    const handleCloseMenu = () => {
        setCloseMenu(!closeMenu);
    };

    const handleShareByLink = (shareByLink) => {
        setVtgVideo(vtgVideo => ({...vtgVideo, shareByLink}));
    }
    
    return (
        <React.Fragment>
            <div className={ closeMenu === false ? "burger-container active" : "burger-container" } title={t('player_navbar_btn_shownavbar_titleattr')}>
                <div onClick={handleCloseMenu} className="burger-trigger">
                    <span className="material-icons-round" style={{fontSize:'32px'}}>
                        keyboard_double_arrow_down
                    </span>
                </div>
            </div>
            <div className={ closeMenu === false ? "video-player-container" : "video-player-container active" }>
                <div className="ui-elements-container">
                    <div className="top-navigation px-4 py-2 d-flex justify-content-between" style={{zIndex:555}}>
                        <div className="d-flex align-items-center">
                            <span onClick={saveLastPlayingAndExit} className="mr-3">
                                <img src="/assets/imgs/logo-white-and-color.svg" alt="Logo ViTAG" height="42px"/>
                            </span>
                            <div className="d-flex flex-column align-items-start border-left pl-3 py-1">
                                <span className="text-white mb-1 video-title">{vtgVideo?.title}</span>
                                { vtgVideo?.shareType === 'owner' && 
                                    <span style={{width:'auto'}} className="badge badge-pill text-white bg-vitag py-2 px-3">
                                        { t('general_sharetype_' + vtgVideo?.shareType) }
                                    </span>}
                                { vtgVideo?.shareType === 'onlyread' && 
                                    <span style={{width:'auto'}} className="badge badge-pill text-white bg-vitag py-2 px-3">
                                        { t('general_sharetype_' + vtgVideo?.shareType) }
                                    </span>}
                                { vtgVideo?.shareType === 'individual' && 
                                    <span style={{width:'auto'}} className="badge badge-pill text-white bg-vitag py-2 px-3">
                                        { t('general_sharetype_' + vtgVideo?.shareType) }
                                    </span>}
                                { vtgVideo?.shareType === 'collaborative' && 
                                    <span style={{width:'auto'}} className="badge badge-pill text-white bg-vitag py-2 px-3">
                                        { t('general_sharetype_' + vtgVideo?.shareType) }
                                    </span>}
                            </div>
                        </div>
                        <div className="text-white d-flex gap-3 align-items-center">
                            { showLocalVideoError &&
                            <button className="btn btn-outline-light" style={{whiteSpace:'nowrap'}}
                                onClick={() => setShowSelectLocalVideoModal(true)}>
                                {t('player_navbar_btn_openvideo')}
                            </button> }
                            <button onClick={handleCloseMenu} id="fullscreen-btn" title={t('player_navbar_btn_hidenavbar_titleattr')}
                                className="btn btn-sm btn-white-text line-0">
                                <span className="material-icons-round" style={{fontSize:'28px'}}>keyboard_double_arrow_up</span>
                            </button>
                            { vtgVideo?.shareType === 'owner' &&
                            <button onClick={showVideoEvents} title={t('player_navbar_btn_showmoreinfo_titleattr')}
                                className="btn btn-sm btn-white-text line-0">
                                <span className="material-icons-round">info</span>
                            </button> }
                            { ! playingVitagSummary &&
                            <button onClick={playVitagSummary} title={t('player_navbar_btn_playclips_titleattr')}
                                className="btn btn-sm btn-white-text line-0">
                                <span className="material-icons-round">video_library</span>
                            </button>}
                            { playingVitagSummary &&
                            <button onClick={stopVitagSummary} title={t('player_navbar_btn_stopclips_titleattr')}
                                className="btn btn-sm btn-white-text line-0">
                                <span className="material-icons-round">stop_circle</span>
                            </button>}
                            { vtgVideo?.shareType === 'owner' &&
                            <button onClick={shareVideo} id="share-btn" title={t('player_navbar_btn_sharevideo_titleattr')}
                                className="btn btn-sm btn-white-text line-0">
                                <span className="material-icons-round">share</span>
                            </button> }
                            <button onClick={triggerSideBar} id="tag-list-btn" title={t('player_navbar_btn_sidebartoggle_titleattr')}
                                className="btn btn-sm btn-white-text line-0">
                                <span className="material-icons-round" style={{fontSize:'28px'}}>search</span>
                            </button>
                            <ProfileIndicator useDarkTheme={false}/>
                        </div>
                    </div>

                    { vtgVideo?.shareType && vtgVideo?.shareType !== 'onlyread' && 
                        <FavoritesBar
                            quickTag={quickTag}
                            setQuickTag={changeQuickTag}
                            favoriteVtgTags={favoriteVtgTags}
                            toggleLockMarkerSummary={toggleLockMarkerSummary}
                            lockMarkerSummary={lockMarkerVitagSummary}
                            listenerOpenFavs={() => setShowModalManageFavs(true)}
                            listenerNewTag={() => setShowNewTagModal(true)}
                            listenerOpenNewMarker={handleClickExtendMarker}
                            listenerNewQuickMarker={handleQuickMarkerClick}/> }

                    { vtgVideo?.videoProvider?.internalId === Typologies.VT_VIMEO && 
                        <VimeoPlayer vimeoVideoId={vtgVideo?.onlineId}
                            videoPlaying={playingVideo} setVideoPlaying={setPlayingVideo}
                            videoDuration={videoDuration} setVideoDuration={setVideoDuration}
                            currentVideoTime={currentTime} setCurrentVideoTime={setCurrentTime}
                            externSeekTo={externSeekTo} externSetVimeoPlayer={setVimeoPlayer}/>
                    }

                    { vtgVideo?.videoProvider?.internalId === Typologies.VT_TWITCH && 
                        <TwitchPlayer twitchVideoId={vtgVideo?.onlineId}
                            videoPlaying={playingVideo} setVideoPlaying={setPlayingVideo}
                            videoDuration={videoDuration} setVideoDuration={setVideoDuration}
                            currentVideoTime={currentTime} setCurrentVideoTime={setCurrentTime}
                            externSeekTo={externSeekTo} externSetTwitchPlayer={setTwitchPlayer}/>
                    }

                    { vtgVideo?.videoProvider?.internalId === Typologies.VT_YOUTUBE &&
                        <iframe id="player" type="text/html"
                            onContextMenu={e => e.preventDefault()}
                            style={{position:'absolute', width:'100%', height:'100vh'}}
                            src={`https://www.youtube.com/embed/${vtgVideo?.onlineId}?controls=1&autohide=1&autoplay=1&rel=0&showinfo=0&enablejsapi=1&modestbranding=1`}
                            frameBorder="0" crossOrigin="anonymous">
                        </iframe>
                    }

                    { ( vtgVideo?.videoProvider?.internalId === Typologies.VT_EXTERN_VIDEO
                        || vtgVideo?.videoProvider?.internalId === Typologies.VT_LOCAL_VIDEO) && 
                        /* <video id="videoFilePlayer" src={vtgVideo?.url} style={{position:'absolute'}}
                            onLoadedMetadata={videoFileLoaded} onError={errorLoadingVideoFile}
                            width="100%" height="100%" controls></video> */
                        <VideoJS
                            videoId="videoFilePlayer"
                            options={videoJsOptions}
                            onReady={handlePlayerReady}
                            projectionType={projectionType}
                            allProjections={allProjections}
                            showModal={showModalManageMarker}
                            showSummary={showSummaryContainer}
                            showFavs={showModalManageFavs}
                            lockMarkerVitagSummary={lockMarkerVitagSummary}
                            disableKeys={disableKeys}
                            showModalMoreInfo={showModalMoreInfo} 
                            showShareModal={showShareModal}>
                        </VideoJS>
                    }

                </div>

                { vtgVideo?.videoProvider?.internalId === Typologies.VT_YOUTUBE &&
                    <div id="ytMarkersBar" className="position-absolute w-auto"
                        style={{bottom:'42px',height:'1px',zIndex:'9',left:'12px',right:'12px'}}></div> }

                <div id="markersSideBar" className={showSideBar ? 'active' : ''}>
                    <MarkersBar
                        vtgVideo={vtgVideo}
                        videoHashId={hashId}
                        videoDuration={videoDuration}
                        videoProvider={vtgVideo?.videoProvider}
                        videoUserId={vtgVideo?.userId}
                        shareType={vtgVideo?.shareType}
                        favoriteTags={favoriteVtgTags}
                        markers={markers}
                        setMarkers={setMarkers}
                        currentMarker={currentMarker}
                        setMarkerSummary={setMarkerSummaryFromMarkerBar}
                        startEditListener={startMarkerEdit}
                        startDeleteListener={confirmDeleteMarker}
                        onlyShowListener={showMarkerDetail}
                        closeSidebarListener={triggerSideBar}
                        moveToListener={sidebarMoveTo}
                        repaintMarkers={repaintMarkers}
                        recentMarkers={recentMarkers}
                        setRecentMarkers={setRecentMarkers}
                        refresh={needRefresh}
                        setFirstAttemptRefresh={setFirstRefresh}
                        firstAttemptRefresh={firstRefresh}
                        itemRefs={itemRefs}
                        setDisableKeys={setDisableKeys} />
                </div>
            </div>

            { errorCode && <div className='position-absolute rounded bg-danger text-white px-3 small' style={{bottom:'3em', right:'3em', zIndex:'2000'}}>
                {errorCode}
            </div> }

            { lockMarkerVitagSummary !== Typologies.LMSO_INACTIVE &&
            showMarkerVitagSummary && 
            <MarkerSummary
                marker={markerSummary}
                setMarker={setMarkerSummary}
                videoId={vtgVideo?.videoId}
                startDeleteListener={confirmDeleteMarker}
                startEditListener={startMarkerEdit}
                startEditTimerListener={startMarkerTimerEdit}
                updateMarkersListener={reloadMarkers}
                onCloseMarkerSummary={closeMarkerSummary}/> }

            <ConfirmModal
                modalTitle={t('modal_localvideo_title')}
                okText='modal_localvideo_btn_ready_titleattr'
                showCancelButton={true} showModal={showSelectLocalVideoModal}
                setShowModal={setShowSelectLocalVideoModal}>
                <Row>
                    <div className="col-12 form-group">
                        <p className='mt-2'>
                            {t('modal_localvideo_extra_text')}
                        </p>
                        <p>
                            {t('modal_localvideo_text')}
                        </p>
                        <div className="input-group mb-2">
                            <div className="custom-file">
                                <input onChange={handleFileSelection} type="file" accept="video/*" className="custom-file-input" id="inputVideoFile" name="inputVideoFile"/>
                                <label className="custom-file-label" htmlFor="inputVideoFile">
                                    {t('modal_localvideo_title', {videotitle: vtgVideo?.title})} 
                                </label>
                            </div>
                        </div>
                    </div>
                </Row>
            </ConfirmModal>

            <ModalButtonPad
                videoId={vtgVideo?.videoId}
                showModal={showNewTagModal}
                setShowModal={setShowNewTagModal}
                savedTagListener={updateFavoriteTags}/>

            <ModalShare
                vtgVideo={vtgVideo}
                showModal={showShareModal}
                saveVideoListener={handleShareByLink}
                setShowModal={setShowShareModal}/>

            <ModalManageMarker
                currentVideoId={vtgVideo?.videoId}
                currentTag={currentTag}
                currentMarker={currentMarker}
                currentThumbnail={currentThumbnail}
                currentTimeAsText={currentDetailTime}
                currentTimeAsSeconds={currentTime}
                currentTimeAsMilliseconds={currentTimeMs}
                forwardControl={goForward}
                backwardControl={goBackward}
                //forwardTimerControl={goTimerForward}
                //backwardTimerControl={goTimerBackward}
                playControl={playVideo}
                pauseControl={pauseVideo}
                playingState={playingVideo}
                newMarkerListener={newMarkerListener}
                confirmDeleteListener={confirmDeleteMarker}
                showModal={showModalManageMarker}
                setShowModal={setShowModalManageMarker}
                showTimerOnly={showTimerOnly}
                setShowTimerOnly={setShowTimerOnly}
                closeMarkerManager={closeMarkerManager}/>

            <ModalViewMarker
                currentMarker={currentMarker}
                currentThumbnail={currentThumbnail}
                currentTimeAsText={currentDetailTime}
                showModal={showModalViewMarker}
                setShowModal={setShowModalViewMarker}/>

            <ModalManageFavs
                videoId={vtgVideo?.videoId}
                availableTags={availableVtgTags}
                currentFavs={favoriteVtgTags}
                setFavorites={setFavoriteVtgTags}
                showModal={showModalManageFavs}
                setShowModal={setShowModalManageFavs}
                savedTagListener={updateFavoriteTags}/>
        
            <ModalShowEvents
                vtgVideo={vtgVideo}
                showModal={showModalMoreInfo}
                setShowModal={setShowModalMoreInfo}/>

            <ConfirmModal
                modalTitle={t('modal_markers_deletemarker_title')}
                modalText={t('modal_markers_deletemarker_text',{time: (currentMarker?.startMilliSecond > 0 ? convertMillisecondsToTime(currentMarker?.startMilliSecond): convertSecondsToTime(currentMarker?.startSecond))})}
                okText='modal_markers_deletemarker_btn_delete_titleattr'
                acceptCallback={deleteCurrentMarker}
                showModal={showConfirmDeleteMarkerModal}
                setShowModal={setShowConfirmDeleteMarkerModal} />

            <ConfirmModal
                showModal={showErrorModal} setShowModal={setShowErrorModal} size='lg'
                modalTitle={t('modal_videos_videoaccesserror_title')}
                cancelCallback={goBack} cancelText='modal_videos_videoaccesserror_btn_goback_titleattr'
                acceptCallback={refreshPage} okIcon='refresh' okText='modal_videos_videoaccesserror_btn_reloadpage_titleattr'>
                    <div className="d-flex flex-column px-5">
                        <p>{t('modal_videos_videoaccesserror_text1_reason')}</p>
                        <ul>
                            <li>{t('modal_videos_videoaccesserror_reason1')}</li>
                            <li>{t('modal_videos_videoaccesserror_reason2')}</li>
                            <li>{t('modal_videos_videoaccesserror_reason3')}</li>
                        </ul>
                        <p>{t('modal_videos_videoaccesserror_text2_solutions')}</p>
                        <ul>
                            <li>{t('modal_videos_videoaccesserror_solution1')}</li>
                            <li>{t('modal_videos_videoaccesserror_solution2')}</li>
                            <li>
                                <Trans i18nKey='modal_videos_videoaccesserror_solution3'>
                                    También puedes intentar solucionarlo abriendo el video 
                                    dando <a href={vtgVideo?.url} target="_blank" className='text-vitag'>click aquí</a> y luego refresca la página
                                </Trans>
                            </li>
                        </ul>
                        <button onClick={() => { setShowErrorModal(false); setShowSelectLocalVideoModal(true); }}
                            className="btn bg-vitag text-white align-self-end mb-3" type='button'>
                            {t('modal_videos_btn_selectvideo')}
                        </button>
                    </div>
            </ConfirmModal>

        </React.Fragment>
    );
}

export { VideoPlayer };
